import { FC } from 'react';
import { BodyBaseRegularText, BodyBigRegularText } from '../../../../../../styles/Text';
import { TasksSidebarItem } from '../Styles';

interface DueDateProps {
  date: string;
}

export const DueDate: FC<DueDateProps> = ({ date }) => {
  return (
    <TasksSidebarItem>
      <BodyBaseRegularText style={{ color: '#787676' }}>Сроки выполнения</BodyBaseRegularText>
      <BodyBigRegularText style={{ color: '#333' }}>{date}</BodyBigRegularText>
    </TasksSidebarItem>
  );
};
