import { FC } from 'react';
import styled from 'styled-components';
import { BodyBaseRegularText } from '../../../../../styles/Text';

const CardInfoItemElement = styled.div`
  display: flex;
  flex-direction: row;

  gap: 6px;
`;

const CardInfoItemImg = styled.img`
  width: 16px;
  height: 16px;
  margin: 3px 0;
`;

export interface CardInfoItemProps {
  imgSrc: string;
  condition: string;
}

export const CardInfoItem: FC<CardInfoItemProps> = ({ imgSrc, condition }) => {
  return (
    <CardInfoItemElement>
      <CardInfoItemImg src={imgSrc} alt={'condition'} />
      <BodyBaseRegularText>{condition}</BodyBaseRegularText>
    </CardInfoItemElement>
  );
};
