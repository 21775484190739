import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`

  html {
    scroll-behavior: smooth; 
    scroll-padding-top: 30vh; 
  }

  body {
    margin: 0;
    min-height: 100vh; 
  }

  #root {
    min-height: 100vh; 
  }

`;
