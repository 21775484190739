import styled from 'styled-components';
import { BodyBigRegularText } from '../../../../../../styles/Text';

export const EventCardContainer = styled.div`
  height: 335px;
  max-width: 388px;

  background-color: #ffffff;
  box-shadow: 0px 4px 7px 1px rgba(0, 0, 0, 0.06);
  border-radius: 6px;
`;

export const EventCardContent = styled.div`
  box-sizing: border-box;
  padding: 25px;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const EventCardDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

export const EventDescription = styled(BodyBigRegularText)`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ButtonArea = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
`;
