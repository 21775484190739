import { useState } from 'react';
import {
  MinPointsSelectionWrapper,
  MinPointsSelectionControlsWrapper,
  MinPointsSelectionControls,
  MinPointsInput,
  MinPointsButton,
} from './Styles';
import { ChooseMinPointsComponentProps } from './types';
import { BodyBigBoldText, BodySmallRegularText, BodyBigRegularText } from '../../../../../../../styles/Text';
import { IProblem } from '../../../../../../../core/types/problem.interface';

const minPointsButtonsOptions = [{ label: '+ 5 баллов', value: '5' }];

const getCountedPointsInSelectedTasks = (selectedTasks: IProblem[]): number => {
  return selectedTasks.reduce((total, task) => total + task.points, 0);
};

const MinPointsHint: React.FC<{ selectedTasks: IProblem[] }> = ({ selectedTasks }) => {
  const overallPoints = getCountedPointsInSelectedTasks(selectedTasks);
  const isOverallPointsEnough = overallPoints > 0;
  return (
    <>
      {isOverallPointsEnough ? (
        <BodyBigRegularText>{`Всего в выбранных задачах - ${overallPoints} баллов`}</BodyBigRegularText>
      ) : (
        <BodyBigRegularText>{`Сейчас не выбрано ни одной задачи`}</BodyBigRegularText>
      )}
    </>
  );
};

export const ChooseMinPointsComponent: React.FC<ChooseMinPointsComponentProps> = ({
  onMinPointsChange,
  selectedTasks,
}) => {
  const [minPointsValidationError, setMinPointsValidationError] = useState<string | null>(null);
  const [minPoints, setMinPoints] = useState<string | number>(5);

  const handleMinPointsManualChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const updatedValue = Number(value);
    setMinPoints(value);
    if (!onMinPointsChange) return;
    if (isNaN(updatedValue)) {
      setMinPointsValidationError('Укажите минимальное количество баллов');
    }
    if (updatedValue <= 0) {
      setMinPointsValidationError('Минимальное количество баллов должно быть больше 0');
    }
    setMinPointsValidationError(null);
    onMinPointsChange(updatedValue);
  };

  const handleMinPointsButtonChange = (points: string) => () => {
    const actualValue = Number(minPoints);
    const plusPoints = Number(points);
    const isActualValueIncorrect = isNaN(actualValue) || actualValue <= 0;
    if (isActualValueIncorrect) {
      setMinPoints(plusPoints);
      onMinPointsChange(plusPoints);
    } else {
      const updatedValue = actualValue + plusPoints;
      setMinPoints(updatedValue);
      onMinPointsChange(updatedValue);
    }
  };

  return (
    <MinPointsSelectionWrapper>
      <BodyBigBoldText>
        Минимальное кол-во баллов <span style={{ color: '#F3163E' }}>*</span>
      </BodyBigBoldText>
      <MinPointsSelectionControlsWrapper>
        <MinPointsSelectionControls>
          <MinPointsInput value={minPoints} onChange={handleMinPointsManualChange} />
          {minPointsButtonsOptions.map(option => (
            <MinPointsButton
              key={option.value}
              name={`set${option.value}min`}
              type="button"
              onClick={handleMinPointsButtonChange(option.value)}
            >
              {option.label}
            </MinPointsButton>
          ))}
        </MinPointsSelectionControls>
        <MinPointsHint selectedTasks={selectedTasks} />
        {minPointsValidationError && (
          <BodySmallRegularText style={{ color: 'rgb(243, 22, 62)' }}>{minPointsValidationError}</BodySmallRegularText>
        )}
      </MinPointsSelectionControlsWrapper>
    </MinPointsSelectionWrapper>
  );
};
