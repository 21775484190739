import { FC } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useActions } from '../../../../../hooks/useActions';
import { useAuth } from '../../../../../hooks/useAuth';
import { ROUTES } from '../../../../../routes/routes.config';
import { BlueButton } from '../../../../common/main/button/BlueButton';
import { Input } from '../../../../common/main/input/Input';
import { PasswordInput } from '../../../../common/main/input/PasswordInput';
import { AuthForm, AuthFormContainer, AuthFormField } from '../../../Styles';
import { AuthErrorMessage } from '../../../ui/AuthErrorMessage';
import { emailValidation } from '../../../validation/email.valid';
import { isuGroupValidation } from '../../../validation/isu-group.valid';
import { isuNumberValidation } from '../../../validation/isu-number.valid';
import { nameValidation } from '../../../validation/name.valid';
import { passwordValidation } from '../../../validation/password.valid';

export interface IRegisterFields {
  isuNumber: number;
  email: string;
  name: string;
  isuGroup: string;
  password: string;
}

export const RegisterForm: FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IRegisterFields>({
    mode: 'onChange',
  });

  const { register: registerAction } = useActions();
  const { isLoading } = useAuth();

  const navigate = useNavigate();

  const onSubmit: SubmitHandler<IRegisterFields> = data => {
    registerAction(data);
    navigate(ROUTES.auth.login.fullPath);
  };

  return (
    <AuthForm onSubmit={handleSubmit(onSubmit)}>
      <AuthFormContainer>
        <AuthFormField>
          <Input {...register('isuNumber', isuNumberValidation)} placeholder="Номер ИСУ" />
          <AuthErrorMessage>{errors.isuNumber?.message}</AuthErrorMessage>
        </AuthFormField>
        <AuthFormField>
          <Input {...register('email', emailValidation)} placeholder="Почта" />
          <AuthErrorMessage>{errors.email?.message}</AuthErrorMessage>
        </AuthFormField>
        <AuthFormField>
          <Input {...register('name', nameValidation)} placeholder="Имя" />
          <AuthErrorMessage>{errors.name?.message}</AuthErrorMessage>
        </AuthFormField>
        <AuthFormField>
          <Input {...register('isuGroup', isuGroupValidation)} placeholder="Группа ИСУ" />
          <AuthErrorMessage>{errors.isuGroup?.message}</AuthErrorMessage>
        </AuthFormField>
        <AuthFormField>
          <PasswordInput {...register('password', passwordValidation)} placeholder="Пароль" />
          <AuthErrorMessage>{errors.password?.message}</AuthErrorMessage>
        </AuthFormField>
      </AuthFormContainer>
      <BlueButton style={{ width: '250px' }} disabled={isLoading}>
        Зарегистрироваться
      </BlueButton>
    </AuthForm>
  );
};
