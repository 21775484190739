import { onlyCyrillicAndLatinRegex } from './auth.valid';

export const nameValidation = {
  required: 'Не указано имя пользователя',
  minLength: {
    message: 'Имя должно иметь больше 3 символов',
    value: 3,
  },
  maxLength: {
    message: 'Имя не должно иметь больше 100 символов',
    value: 100,
  },
  pattern: {
    value: onlyCyrillicAndLatinRegex,
    message: 'Имя должно состоять из английских/русских букв',
  },
};
