import styled from 'styled-components';
import {
  BodyBigBoldText,
  BodyBigRegularText,
  ButtonBigText,
  MainHeadingText,
  SecondaryHeadingText,
} from '../../../../styles/Text';
import { StyledNavLink } from '../../Styles';
import { StyledTable } from '../../../common/main/table/StyledTable';

const TaskPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const TaskInfoContainer = styled.div`
  height: 114px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const GeneralTaskInfoContainer = styled.div``;

const TaskNameText = styled(MainHeadingText)`
  color: #333333;
`;

const TaskTypeText = styled(BodyBigRegularText)`
  color: #333333;
`;

const EditTaskIconContainer = styled.div`
  height: 24px;
  width: 24px;
  text-align: center;

  .img {
    width: 100%;
  }
`;

const StyledNavLinkWithBackground = styled(StyledNavLink)`
  background-color: #0b68fe;
  height: 24px;
  width: 146px;
  padding: 8px 16px 8px 18px;
  gap: 12px;
  justify-content: space-between;
`;

const EditTaskText = styled(ButtonBigText)`
  outline: none;
  border: none;
  background-color: transparent;
  color: #ffffff;
  padding: 0;
  cursor: pointer;
`;

const TaskStatsContainer = styled.div`
  display: flex;
  gap: 20px;
`;

const TaskStatsSmallCard = styled.div`
  background-color: #ffffff;
  width: 535px;
  height: 121px;
  padding: 20px 30px;
  border-radius: 6px;
  box-shadow: 0 4px 7px 1px rgba(0, 0, 0, 0.06);
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const TaskStatsCardHeading = styled(BodyBigBoldText)`
  color: #333333;
`;

const TaskStatsCardInfoContainer = styled.div`
  display: grid;
  grid-template-columns: max-content minmax(0, 1fr);
  grid-gap: 8px;
`;

const StatsRow = styled.div`
  display: contents;
`;

const StatsNameText = styled(BodyBigRegularText)`
  color: #787676;
`;

const StatsValueText = styled(BodyBigRegularText)`
  color: #333333;
`;

const TaskResultsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const TaskResultsText = styled(SecondaryHeadingText)`
  color: #333333;
`;

const StyledStudentResultsTable = styled(StyledTable)`
  th:last-child,
  td:last-child {
    text-align: right;
  }
`;

export {
  TaskPageContainer,
  TaskInfoContainer,
  GeneralTaskInfoContainer,
  TaskNameText,
  TaskTypeText,
  EditTaskIconContainer,
  StyledNavLinkWithBackground,
  EditTaskText,
  TaskStatsContainer,
  TaskStatsSmallCard,
  TaskStatsCardHeading,
  TaskStatsCardInfoContainer,
  StatsRow,
  StatsNameText,
  StatsValueText,
  TaskResultsText,
  TaskResultsContainer,
  StyledStudentResultsTable,
};
