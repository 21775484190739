export function convertMinutesToDurationFormat(minutes: number) {
  const totalMinutes = Math.floor(minutes);
  const day = Math.floor(totalMinutes / (24 * 60));
  const hour = Math.floor((totalMinutes % (24 * 60)) / 60);
  const min = totalMinutes % 60;
  const sec = 0;

  return `${String(day).padStart(2, '0')}.${String(hour).padStart(2, '0')}:${String(min).padStart(2, '0')}:${String(
    sec,
  ).padStart(2, '0')}`;
}
