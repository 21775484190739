import { forwardRef } from 'react';
import { IInput } from '../../types/input.interface';
import styled from 'styled-components';

export const TaskFormInputStyled = styled.input`
  box-sizing: border-box;
  width: 100%;

  border: 1px solid #e1e1e1;
  border-radius: 6px;
  padding: 8px 12px;

  color: #787676;

  &:focus {
    outline: none;
    border-color: #0b68fe;
  }

  &:focus::placeholder {
    color: transparent;
  }

  //TODO: use BodyBigRegularText
  margin: 0;
  font-family: 'Noto Sans', sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: normal;
`;
export const TaskFormInput = forwardRef<HTMLInputElement, IInput>(({ ...rest }, ref) => {
  return <TaskFormInputStyled ref={ref} {...rest} />;
});
