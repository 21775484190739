import styled from 'styled-components';
import { TaskFormInput } from '../../../../../../common/main/form/TaskFormInput';
import { BlueButton } from '../../../../../../common/main/button/BlueButton';

export const MinPointsSelectionWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const MinPointsSelectionControlsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const MinPointsSelectionControls = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
`;

export const MinPointsInput = styled(TaskFormInput)`
  width: 88px;
  height: 36px;
`;

export const MinPointsButton = styled(BlueButton)`
  height: 28px;
  width: 93px;
  padding: 0px;
`;
