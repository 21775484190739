import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { eventApi } from '../../core/api/rtk-query/event.api';

export const useGetCurrentEvent = () => {
  const { data: events, isLoading: eventsIsLoading } = eventApi.useGetEventsQuery();
  const { data: userEvents, isLoading: userEventsIsLoading } = eventApi.useGetUserEventsQuery();
  const isLoading = eventsIsLoading || userEventsIsLoading;

  const { taskId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      !isLoading &&
      !events?.map(event => event.id).includes(taskId || '') &&
      !userEvents?.map(userEvent => userEvent.eventId).includes(taskId || '')
    ) {
      navigate(-1);
    }
  }, [isLoading]);

  return {
    event: events?.find(event => event.id === taskId),
    userEvent: userEvents?.find(userEvent => userEvent.eventId === taskId),
    isLoading,
  };
};
