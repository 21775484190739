import { FC, PropsWithChildren } from 'react';
import { MainHeadingText } from '../../../styles/Text';

interface AuthTitleProps {
  style?: object;
}

export const AuthTitle: FC<PropsWithChildren<AuthTitleProps>> = ({ children, style }) => {
  return <MainHeadingText style={{ marginBottom: '40px', marginTop: '0px', ...style }}>{children}</MainHeadingText>;
};
