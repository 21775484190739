import { useState } from 'react';
import { ChooseTaskContent, EmptyTaskList, SelectedTasksList } from './Styles';
import { ChooseTaskProps } from './types';
import { BlueButton } from '../../../../common/main/button/BlueButton';
import { WhiteButton } from '../../../../common/main/button/WhiteButton';
import { BodyBigRegularText } from '../../../../../styles/Text';
import { TaskBankPopup } from '../taskBank/TaskBankPopup';
import { TaskCard } from '../taskBank/taskCard';

export const ChooseTask = ({ selectTasks, selectedTasks }: ChooseTaskProps) => {
  const [isShowPopup, setIsShowPopup] = useState(false);

  const openPopup = () => setIsShowPopup(true);
  const closePopup = () => setIsShowPopup(false);

  return (
    <ChooseTaskContent>
      {!selectedTasks.length && (
        <EmptyTaskList>
          <BodyBigRegularText>Вы еще не выбрали ни одной задачи!</BodyBigRegularText>
          <BlueButton style={{ width: '200px', height: '40px' }} onClick={openPopup}>
            {'Выбрать задачи'}
          </BlueButton>
        </EmptyTaskList>
      )}
      {selectedTasks.length > 0 && (
        <>
          <SelectedTasksList>
            {selectedTasks?.map(problem => {
              return <TaskCard key={`${problem.hash}-${Math.random()}`} {...problem}></TaskCard>;
            })}
          </SelectedTasksList>
          <WhiteButton style={{ width: '200px', height: '40px' }} onClick={openPopup}>
            Редактировать
          </WhiteButton>
        </>
      )}
      {isShowPopup && <TaskBankPopup onClose={closePopup} onSave={selectTasks} taskListToEdit={selectedTasks} />}
    </ChooseTaskContent>
  );
};
