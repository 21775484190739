import { FC } from 'react';
import styled from 'styled-components';
import { userApi } from '../../../core/api/rtk-query/user.api';
import { BodyBigRegularText, BodySmallRegularText } from '../../../styles/Text';

const PersonalInformationNameText = styled(BodyBigRegularText)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const PersonalInformationContainer = styled.div`
  flex: 1;
  width: 208px;
  height: 42px;
  display: flex;
  flex-direction: column;
  text-align: right;
`;

export const PersonalInformation: FC = () => {
  const { data, isLoading } = userApi.useGetUserQuery();

  if (isLoading) return null;

  return (
    <PersonalInformationContainer>
      <PersonalInformationNameText>{data?.name}</PersonalInformationNameText>
      <BodySmallRegularText style={{ color: '#787676' }}>{data?.isuNumber}</BodySmallRegularText>
    </PersonalInformationContainer>
  );
};
