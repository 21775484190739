import { forwardRef, useState } from 'react';
import styled from 'styled-components';
import { Score } from '../../../../hooks/useGetCurrentEventScore';
import { IInput } from '../../types/input.interface';
import { Input } from './Input';
import { useGetCurrentEventTimeInfo } from '../../../../hooks/useGetCurrentEventTimeInfo';
import { STATUS_COLORS } from '../../../../styles/constants';

const wrong: string = require('../../../../assets/icons/main/wrong.svg').default;
const success: string = require('../../../../assets/icons/main/success.svg').default;

interface TaskInputStyledProps {
  borderColor: string;
}

const TaskInputStyled = styled.div<TaskInputStyledProps>`
  width: 350px;
  display: flex;
  justify-content: space-between;

  border: 1px solid;
  border-color: ${p => p.borderColor};
  border-radius: 6px;

  input {
    padding-right: 0;
  }
`;

const Img = styled.img`
  width: 24px;
  height: 24px;
  padding: 8px 12px;
`;

export interface TaskInputProps extends IInput {
  score: Score;
  reRender: boolean;
}

type getStatusProps = { score: Score; isFocus: boolean; isUserEventStillActive: boolean };
const getStatus = ({ score, isFocus, isUserEventStillActive }: getStatusProps) => {
  const isUserAnswerCorrect = score.all === score.score;
  const isUserCanAnswer = !score.isSend && isUserEventStillActive;
  const isExamDone = score.isSend && !isUserEventStillActive;
  const isHomeworkDone = score.isSend && score.isShown;
  const isUserCanSeeResult = isExamDone || isHomeworkDone;
  const isTaskHasNoResponse = !score.isSend && !isUserEventStillActive;

  if (isUserCanAnswer && isFocus) {
    return 'active';
  }

  if (isUserCanAnswer) {
    return 'default';
  }

  if (isUserCanSeeResult && isUserAnswerCorrect) {
    return 'correct';
  }

  if ((isUserCanSeeResult && !isUserAnswerCorrect) || isTaskHasNoResponse) {
    return 'incorrect';
  }

  return 'default';
};

export const TaskInput = forwardRef<HTMLInputElement, TaskInputProps>(({ score, reRender, ...rest }, ref) => {
  const [isFocus, setIsFocus] = useState<boolean>(false);
  const { isUserEventStillActive } = useGetCurrentEventTimeInfo();
  const status = getStatus({ score, isFocus, isUserEventStillActive });
  const isResultShown = score.isShown && score.isSend;
  const isInputStatusCanBeShown = isResultShown || !isUserEventStillActive;

  return (
    <TaskInputStyled
      tabIndex={0}
      ref={ref}
      onFocus={() => setIsFocus(true)}
      onBlur={() => setIsFocus(false)}
      borderColor={STATUS_COLORS[status]}
    >
      <Input style={{ border: '0' }} {...rest} />
      {isInputStatusCanBeShown && <Img src={status == 'correct' ? success : wrong} />}
    </TaskInputStyled>
  );
});
