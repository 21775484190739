import { FC } from 'react';
import { ROUTES } from '../../../../../routes/routes.config';
import { NavElementList } from '../../../Styles';
import { NavElement } from './element/NavElement';

const TeacherPageSideBarData = [
  {
    title: 'Курсы',
    path: ROUTES.teacher.groups.fullPath,
    iconPath: require('../../../../../assets/icons/book-open.svg').default,
  },
  {
    title: 'Задания',
    path: ROUTES.teacher.tasks.fullPath,
    iconPath: require('../../../../../assets/icons/file-text.svg').default,
  },
  {
    title: 'Студенты',
    path: ROUTES.teacher.students.fullPath,
    iconPath: require('../../../../../assets/icons/people-outline.svg').default,
  },
];

export const NavElements: FC = () => {
  return (
    <NavElementList>
      {TeacherPageSideBarData.map(item => {
        return <NavElement key={item.title} title={item.title} path={item.path} iconPath={item.iconPath} />;
      })}
    </NavElementList>
  );
};
