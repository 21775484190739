import styled from 'styled-components';

export const CreateTaskFormStyled = styled.form`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100%;
  gap: 50px;
  flex: 1 0 0;
  margin-right: 57px;
`;

export const CountSymbols = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

export const ButtonStyled = styled.div`
  display: flex;
  width: 100%;
  height: 40px;
  padding: 8px 20px;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
`;

export const CreateTaskFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
`;

export const CreateTaskFormField = styled.div`
  display: flex;
  width: 715px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
`;

export const RadioButtonStyled = styled.div`
  display: flex;
  width: 140px;
  align-items: center;
  gap: 8px;
`;

export const AssigneeContainerStyled = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
`;
