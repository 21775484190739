import { FC, PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { BodyBigRegularText } from '../../../styles/Text';

const AuthLinkStyled = styled(Link)`
  margin-top: 18px;
  color: #0b68fe;
`;

interface AuthLinkProps {
  to: string;
  style?: object;
}

export const AuthLink: FC<PropsWithChildren<AuthLinkProps>> = ({ to, children, style }) => {
  return (
    <AuthLinkStyled to={to} style={{ ...style }}>
      <BodyBigRegularText>{children}</BodyBigRegularText>
    </AuthLinkStyled>
  );
};
