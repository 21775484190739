import { useGetCurrentEvent } from './api/useGetCurrentEvent';
import { EventType } from '../core/types/event.interface';

export interface Score {
  isShown: boolean;
  isSend?: boolean;
  score?: number;
  all?: number;
}

export const useGetCurrentEventScore = () => {
  const { event, userEvent } = useGetCurrentEvent();
  const scores: Score[] | undefined =
    event && userEvent
      ? event.problems.map(problem => {
          const attempt = userEvent.problemAttempts.find(attempt => attempt.problemId === problem.id);
          return {
            isShown: EventType[event.type] === EventType.Homework,
            isSend: attempt?.sentMessage !== '',
            score: attempt?.points,
            all: problem.points,
          };
        })
      : undefined;

  return { scores };
};
