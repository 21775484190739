import { emailRegex } from './auth.valid';

export const emailValidation = {
  required: 'Не указана почта',
  minLength: {
    message: 'Почта должна иметь больше 5 символов',
    value: 5,
  },
  maxLength: {
    message: 'Почта не должен иметь больше 100 символов',
    value: 100,
  },
  pattern: {
    value: emailRegex,
    message: 'Почта имеет неправильный формат',
  },
};
