import { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { BodyBigBoldText } from '../../../../../../styles/Text';
import { BlueButton } from '../../../button/BlueButton';
import { CardInfo } from '../../../card/CardInfo';
import { ProgressBar } from '../../../progressbar/ProgressBar';
import { GroupCardContainer, GroupCardDiv, GroupCardContent } from './styles';
import { GroupCardProps } from './types';

export const GroupCard: FC<GroupCardProps> = ({ id, name, info, point }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const handleClick = () => {
    navigate(`${pathname}/${id}`);
  };

  return (
    <GroupCardContainer>
      <GroupCardContent>
        <GroupCardDiv style={{ gap: '10px' }}>
          <BodyBigBoldText>{name}</BodyBigBoldText>
          <CardInfo conditions={info} />
        </GroupCardDiv>
        <GroupCardDiv style={{ gap: '25px' }}>
          {point && <ProgressBar {...point} />}
          <BlueButton style={{ width: '250px', alignSelf: 'center' }} onClick={handleClick}>
            Продолжить
          </BlueButton>
        </GroupCardDiv>
      </GroupCardContent>
    </GroupCardContainer>
  );
};
