import { ITaskFields } from '../../../components/student/tasks/ui/task/card/ui/TaskFooter';
import { IPaginationProps } from '../../types/base.interface';
import { IPoints, IProblems } from '../../types/problem.interface';
import { api } from '../api';

interface IGetProblems extends IPaginationProps {
  prefix: string;
}

interface ICheckAnswer extends ITaskFields {
  problemId?: string;
  eventId?: string;
}

export const problemApi = api.injectEndpoints({
  endpoints: builder => ({
    getProblems: builder.query<IProblems, IGetProblems>({
      query: ({ pageNumber, pageSize, prefix }) => `/problems/${pageNumber}/${pageSize}/${prefix}`,
      providesTags: ['Problems'],
    }),
    checkAnswer: builder.mutation<IPoints, ICheckAnswer>({
      query: body => ({
        url: `/problems/check-answer`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (result, error, arg) => [
        {
          type: 'UserEvents',
          eventId: arg.eventId,
        },
      ],
    }),
  }),
});
