import React, { FC } from 'react';
import { EditTaskIconContainer } from '../../../Styles';

export const EditTaskIcon: FC = () => {
  const iconPath: string = require('../../../../../../../assets/icons/edit.svg').default;

  return (
    <EditTaskIconContainer>
      <img src={iconPath} alt={'edit'} />
    </EditTaskIconContainer>
  );
};
