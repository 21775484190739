import styled from 'styled-components';
import { TaskFormInput } from '../../../../../../common/main/form/TaskFormInput';
import { BlueButton } from '../../../../../../common/main/button/BlueButton';

export const DurationSelectorWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const DurationControls = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
`;

export const DurationButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 5px;
`;

export const DurationInput = styled(TaskFormInput)`
  width: 88px;
  height: 36px;
`;

export const SetDurationButton = styled(BlueButton)`
  height: 28px;
  width: 93px;
  padding: 0px;
`;
