import { useState } from 'react';
import { MainHeadingText, SecondaryHeadingText } from '../../../../styles/Text';
import styled from 'styled-components';
import { CreateNewTask } from './ui/CreateNewTask/CreateNewTask';
import { ChooseTask } from './ui/ChooseTask';
import { IProblem } from '../../../../core/types/problem.interface';

export const StyledPageContent = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: flex-start;
  gap: 57px;
  flex: 1 0 0;
  align-self: stretch;
`;

const RightLine = styled.div`
  border-right: 1px solid #e1e1e1;
  height: 100%;
`;

export const CreateTaskPage = () => {
  const [selectedTasks, setSelectedTasks] = useState<IProblem[]>([]);

  return (
    <>
      <MainHeadingText style={{ marginBottom: '15px' }}>Новое задание</MainHeadingText>

      <StyledPageContent>
        <RightLine>
          <div>
            <SecondaryHeadingText style={{ marginBottom: '15px' }}>О задании</SecondaryHeadingText>
            <CreateNewTask selectedTasks={selectedTasks} />
          </div>
        </RightLine>

        <div>
          <SecondaryHeadingText style={{ marginBottom: '15px' }}>Просмотр задач</SecondaryHeadingText>
          <ChooseTask selectTasks={setSelectedTasks} selectedTasks={selectedTasks} />
        </div>
      </StyledPageContent>
    </>
  );
};
