import styled from 'styled-components';

export const MainHeadingText = styled.h1`
  margin: 0;
  font-size: 30px;
  line-height: 40px;
  font-weight: bold;
  font-family: 'Montserrat', sans-serif;
`;

export const SecondaryHeadingText = styled.h2`
  margin: 0;
  font-size: 24px;
  line-height: 32px;
  font-weight: bold;
  font-family: 'Montserrat', sans-serif;
`;

export const SecondaryHeadingTextForEmptyStates = styled(SecondaryHeadingText)`
  color: grey;
  margin-top: 30px;
  font-size: 20px;
`;

const BodyText = styled.p`
  margin: 0;
  font-family: 'Noto Sans', sans-serif;
`;

const BodyBigText = styled(BodyText)`
  font-size: 16px;
  line-height: 24px;
`;

export const BodyBigRegularText = styled(BodyBigText)`
  font-weight: normal;
`;

export const BodyBigBoldText = styled(BodyBigText)`
  font-weight: bold;
  hyphens: auto;
`;

export const BodyBigBoldTextLikeH2 = styled(BodyBigBoldText)`
  font-size: 1.5em;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
`;

export const BodyBigBoldTextLikeH1 = styled(BodyBigBoldText)`
  font-size: 2em;
`;

const BodyBaseText = styled(BodyText)`
  font-size: 14px;
  line-height: 22px;
`;

export const BodyBaseRegularText = styled(BodyBaseText)`
  font-weight: normal;
`;

export const BodyBaseBoldText = styled(BodyBaseText)`
  font-weight: bold;
`;

export const BodySmallText = styled(BodyText)`
  font-size: 12px;
  line-height: 18px;
`;

export const BodySmallRegularText = styled(BodySmallText)`
  font-weight: normal;
`;

export const BodySmallSemiBoldText = styled(BodySmallText)`
  font-weight: 600;
`;

const BodyExtraSmallText = styled(BodyText)`
  font-size: 10px;
  line-height: 16px;
`;

export const BodyExtraSmallSemiBoldText = styled(BodyExtraSmallText)`
  font-weight: 600;
`;

const ButtonText = styled.button`
  margin: 0;
  font-weight: 600;
  font-family: 'Noto Sans', sans-serif;
`;

export const ButtonBigText = styled(ButtonText)`
  font-size: 14px;
  line-height: 20px;
`;

export const ButtonSmallText = styled(ButtonText)`
  font-size: 12px;
  line-height: 18px;
`;
