import { FC, useEffect, useState } from 'react';
import { eventApi } from '../../../../core/api/rtk-query/event.api';
import { groupApi } from '../../../../core/api/rtk-query/group.api';
import { useAuth } from '../../../../hooks/useAuth';
import { Role } from '../../../../core/types/user.interface';
import { MainHeadingText, SecondaryHeadingTextForEmptyStates } from '../../../../styles/Text';
import { getGroupMaxPoints, getGroupUserPoints } from '../../../../utils/cards.utils';
import { getDayjs } from '../../../../utils/dayjs.utils';
import { GroupsGrid } from './grid/GroupsGrid';
import { GroupCardProps } from './grid/card/types';
import { TabInfo, Tabs } from '../tabs/Tabs';
import { StudentGroupsWrapper } from '../../../student/groups/Styles';
import { GroupsPageContainerProps, GroupsPageFromUserTypeProps } from './types';

import { getInfo } from '../../../../utils/cards.utils';

const groupTabs: TabInfo[] = [{ name: 'Активные курсы' }, { name: 'Пройденные курсы' }];

const GroupsPageContainer: FC<GroupsPageContainerProps> = ({ isLoading, groups, activeTab }) => {
  if (isLoading) {
    return <SecondaryHeadingTextForEmptyStates>ЗАГРУЗКА</SecondaryHeadingTextForEmptyStates>;
  }

  if (!groups?.length) {
    return (
      <SecondaryHeadingTextForEmptyStates>
        {activeTab === 0 ? 'Вы пока не записаны на курсы' : 'У вас пока нет завершенных курсов'}
      </SecondaryHeadingTextForEmptyStates>
    );
  }

  return <StudentGroupsWrapper>{groups && <GroupsGrid groups={groups} />}</StudentGroupsWrapper>;
};

const GroupsPageFromUserType: FC<GroupsPageFromUserTypeProps> = ({
  isStudent,
  isLoading,
  groups,
  activeTab,
  setActiveTab,
}) => {
  if (isStudent) {
    return (
      <>
        <MainHeadingText style={{ marginBottom: 30 }}>Мои курсы</MainHeadingText>
        <Tabs tabs={groupTabs} activeTab={activeTab} handleClick={setActiveTab} />
        <GroupsPageContainer isLoading={isLoading} groups={groups} activeTab={activeTab} />
      </>
    );
  }

  if (!isStudent) {
    return (
      <>
        <MainHeadingText style={{ marginBottom: 30 }}>Курсы</MainHeadingText>
        <GroupsPageContainer isLoading={isLoading} groups={groups} activeTab={activeTab} />
      </>
    );
  }

  return <MainHeadingText style={{ marginBottom: 30 }}>ЗАГРУЗКА</MainHeadingText>;
};

export const GroupsSection: FC = () => {
  const { role } = useAuth();

  const [activeTab, setActiveTab] = useState<number>(0);
  const [groups, setGroups] = useState<GroupCardProps[] | undefined>(undefined);

  const { data: groupActive, isLoading: groupActiveIsLoading } = groupApi.useGetActiveGroupQuery();
  const { data: groupArchived, isLoading: groupArchivedIsLoading } = groupApi.useGetArchivedGroupQuery();
  const { data: events, isLoading: eventsIsLoading } = eventApi.useGetEventsQuery();
  const { data: userEvents, isLoading: userEventsIsLoading } = eventApi.useGetUserEventsQuery();
  const isLoading = groupActiveIsLoading || groupArchivedIsLoading || eventsIsLoading || userEventsIsLoading;

  const isStudent = role === Role.Student;

  useEffect(() => {
    if (!isLoading) {
      const groupData = activeTab === 0 ? groupActive : groupArchived;
      setGroups(
        groupData?.map(group => {
          const groupStartTime = getDayjs(group.startTime);
          const groupEndTime = getDayjs(group.endTime);
          const filteredEventsByGroups = events?.filter(event => event.groupId === group?.id);
          return {
            id: group.id,
            name: group.name,
            info: getInfo({
              date: {
                start: groupStartTime,
                end: groupEndTime,
              },
              eventsCount: filteredEventsByGroups?.length,
            }),
            point: isStudent
              ? {
                  name: 'Прогресс выполнения заданий',
                  max: getGroupMaxPoints(events, group.id),
                  now: getGroupUserPoints(userEvents, events, group.id),
                }
              : undefined,
          };
        }),
      );
    }
  }, [isLoading, activeTab, groupActive, groupArchived, events, userEvents]);

  return (
    <GroupsPageFromUserType
      isStudent={isStudent}
      isLoading={isLoading}
      groups={groups}
      activeTab={activeTab}
      setActiveTab={setActiveTab}
    />
  );
};
