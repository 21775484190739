import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { EventType } from '../../../../../core/types/event.interface';
import { useGetCurrentEvent } from '../../../../../hooks/api/useGetCurrentEvent';
import { getDueDate } from '../../../../../utils/dayjs.utils';
import { Score } from '../../../../../hooks/useGetCurrentEventScore';
import { DueDate } from './ui/DueDate';
import { MarksEarned } from './ui/MarksEarned';
import { TasksInfo } from './ui/TasksInfo';
import { Timer } from './ui/Timer/Timer';
import { useGetCurrentEventTimeInfo } from '../../../../../hooks/useGetCurrentEventTimeInfo';

const TasksSidebarContainer = styled.div`
  position: relative;
`;

const TasksSidebarList = styled.div`
  position: sticky;
  top: 89px;

  width: 215px;
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

interface TaskSidebarProps {
  isActive: boolean;
  scores: Score[];
}

export const TasksSidebar: FC<TaskSidebarProps> = ({ isActive, scores }) => {
  const { event, isLoading } = useGetCurrentEvent();
  const { eventStartDate, eventEndDate, userTimerToCompleteActivatedEvent, isUserEventStillActive } =
    useGetCurrentEventTimeInfo();

  const navigate = useNavigate();

  const getSum = (arr: (number | undefined)[]) => {
    var sum = 0;
    arr.forEach(item => {
      if (item) {
        sum += item;
      }
    });
    return sum;
  };

  const dueDate = getDueDate(eventStartDate!, eventEndDate!);
  const time = userTimerToCompleteActivatedEvent;

  if (isLoading) return null;
  return (
    <TasksSidebarContainer>
      <TasksSidebarList>
        {isActive && dueDate && EventType[event!.type] === EventType.Homework && <DueDate date={dueDate} />}
        {isActive && time && EventType[event!.type] !== EventType.Homework && (
          <Timer timeInSeconds={Math.floor(time / 1000)} />
        )}
        {!isActive && (
          <MarksEarned all={getSum(scores.map(score => score.all))} done={getSum(scores.map(score => score.score))} />
        )}
        {event && (
          <TasksInfo
            scores={scores}
            isHideScores={EventType[event.type] !== EventType.Homework}
            isActive={isActive}
            isUserEventStillActive={isUserEventStillActive}
          />
        )}
      </TasksSidebarList>
    </TasksSidebarContainer>
  );
};
