import { IUser } from '../../types/user.interface';
import { api } from '../api';

export const userApi = api.injectEndpoints({
  endpoints: builder => ({
    getUser: builder.query<IUser, void>({
      query: () => `/users`,
      providesTags: () => [{ type: 'User' }],
    }),
  }),
});
