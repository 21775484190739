/** Regex for required capital letter */
export const requiredCapitalLetterRegex = /.*[A-ZА-ЯЁ].*/;

/** Regex for required lower case letter */
export const requiredLowerCaseLetterRegex = /.*[a-zа-яё].*/;

/** Regex for required number */
export const requiredNumberRegex = /.*[0-9].*/;

/** Regex for required letter */
export const requiredLetterRegex = /.*[A-Za-zА-ЯЁа-яё].*/;

/** Regex for required symbols for password */
export const requiredSpecialSymbolRegex = /.*[!?*.#_^$@№&].*/;

/** Regex for email */
export const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,}$/g;

/** Only cyrillic or latin symbols and space */
export const onlyCyrillicAndLatinRegex = /^[A-Za-zА-ЯЁа-яё\s]*$/;

/** Only numbers */
export const onlyNumbersRegex = /^[0-9]*$/;
