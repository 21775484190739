import styled from 'styled-components';
import { BodyBaseRegularText, BodyBigRegularText } from '../../../../styles/Text';
import { NavLink } from 'react-router-dom';

export const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-radius: 6px;
  border-style: hidden;
  overflow: hidden;
  box-shadow: 0 0 0 1px #e1e1e1;

  th {
    background-color: #dfdfdf;
    text-align: left;
  }

  th,
  td {
    padding: 10px 0;

    :first-child {
      padding-left: 20px;
    }

    :last-child {
      padding-right: 20px;
    }
  }

  tr {
    border: 1px solid #e1e1e1;
  }
`;

export const TableHeadText = styled(BodyBaseRegularText)`
  color: #787676;
`;

export const TableCellText = styled(BodyBigRegularText)`
  color: #333333;
`;

export const TableNavLink = styled(NavLink)`
  color: #0b68fe;
`;

export const TableNavLinkText = styled(BodyBigRegularText)``;
