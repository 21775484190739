import { useState } from 'react';
import styled from 'styled-components';
import { BodyBigBoldText, BodySmallSemiBoldText, BodySmallRegularText } from '../../../../../styles/Text';
import { BlueButton } from '../../../../common/main/button/BlueButton';
import { WhiteButton } from '../../../../common/main/button/WhiteButton';
import { IProblem } from '../../../../../core/types/problem.interface';
import { Latex } from '../../../../common/main/latex/Latex';

const TaskCardStyled = styled.div`
  min-width: 544px;
  padding: 20px 30px 30px 30px;
  border-radius: 6px;
  box-shadow: 0 1px 5px 1px rgba(0, 0, 0, 0.3);
  background-color: white;
  margin: 15px 5px 5px;
`;

const TaskCardContentStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100%;
`;

const TaskInfoStyled = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const TaskInteractiveStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const SetPointsStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  //visibility: hidden;
`;

const SetPointsInputStyled = styled.input`
  height: 36px;
  width: 48px;
  border: solid #e7e7e7 1px;
  border-radius: 6px;
  padding: 0;
  margin-top: 8px;
  -webkit-appearance: none;
  color: #787676;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;

  text-align: center;

  &:focus {
    border: solid #035bdf 1px;
    outline: none;
    -webkit-appearance: none;
  }

  &:hover {
    -webkit-appearance: none;
  }

  &:active {
    border: solid #035bdf 1px;
    outline: none;
    -webkit-appearance: none;
  }
`;

interface TaskCardProps extends IProblem {
  isTaskSelected?: boolean;
  onAdd?: () => void;
  onDelete?: () => void;
  onPointsChange?: (taskHash: string, points: number) => void;
  isPointsChangeValidationError?: (isError: boolean) => void;
  points: number;
}

export const TaskCard = (props: TaskCardProps) => {
  const { isTaskSelected, onAdd, onDelete, onPointsChange, isPointsChangeValidationError } = props;

  const [points, setPoints] = useState<string | number>(props.points);
  const [pointsValidationError, setPointsValidationError] = useState<string | null>(null);

  const handleManualPointsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const updatedValue = Number(value);

    setPoints(updatedValue);

    if (!onPointsChange) return;

    if (isNaN(updatedValue)) {
      setPointsValidationError('Укажите баллы');
      isPointsChangeValidationError?.(true);
      return;
    }

    if (updatedValue <= 0) {
      setPointsValidationError('Укажите баллы больше 0');
      isPointsChangeValidationError?.(true);
      return;
    }

    setPointsValidationError(null);
    onPointsChange(props.hash, updatedValue);
    isPointsChangeValidationError?.(false);
  };

  return (
    <TaskCardStyled>
      <TaskCardContentStyled>
        <TaskInfoStyled>
          <BodyBigBoldText style={{ wordBreak: 'break-all', textOverflow: 'ellipsis', width: '100%' }}>
            {props.name}
          </BodyBigBoldText>

          <Latex content={props.russianCondition} />
        </TaskInfoStyled>

        {isTaskSelected && onDelete && (
          <TaskInteractiveStyled>
            <SetPointsStyled>
              <BodySmallSemiBoldText>Баллы</BodySmallSemiBoldText>

              <SetPointsInputStyled value={points} onChange={handleManualPointsChange} />
              {pointsValidationError && (
                <BodySmallRegularText style={{ color: 'rgb(243, 22, 62)', width: 'min-content' }}>
                  {pointsValidationError}
                </BodySmallRegularText>
              )}
            </SetPointsStyled>

            <WhiteButton style={{ height: '35px', width: '48px' }} onClick={onDelete}>
              -
            </WhiteButton>
          </TaskInteractiveStyled>
        )}

        {!isTaskSelected && onAdd && (
          <TaskInteractiveStyled style={{ justifyContent: 'end' }}>
            <BlueButton style={{ height: '35px', width: '48px' }} onClick={onAdd}>
              +
            </BlueButton>
          </TaskInteractiveStyled>
        )}
      </TaskCardContentStyled>
    </TaskCardStyled>
  );
};
