import styled from 'styled-components';
import React, { FC } from 'react';

const SearchBarContainer = styled.div`
  height: 24px;
  padding: 8px 12px;
  display: flex;
  gap: 10px;
  background-color: #ffffff;
  border-radius: 6px;
  border: 1px solid #e1e1e1;
`;

const SearchInput = styled.input`
  // TODO use text styles
  margin: 0;
  font-family: 'Noto Sans', sans-serif;
  width: 100%;
  font-size: 16px;
  line-height: 24px;
  font-weight: normal;
  border: none;
  outline: none;
  color: #787676;
`;

const SearchIconContainer = styled.div`
  width: 24px;
  height: 24px;

  .img {
    width: 100%;
  }
`;

export interface SearchBarProps {
  onFilterTextChange?: (text: string) => void;
  // TODO use React.InputHTMLAttributes<T>
  placeholder?: string;
}

const SearchIcon: FC = () => {
  const image: string = require('../../../../assets/icons/search.svg').default;

  return (
    <SearchIconContainer>
      <img src={image} alt={'search'} />
    </SearchIconContainer>
  );
};

export const SearchBar: FC<SearchBarProps> = ({ onFilterTextChange, placeholder }) => {
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const text = e.target.value;
    if (onFilterTextChange) {
      onFilterTextChange(text);
    }
  };

  return (
    <SearchBarContainer>
      <SearchIcon />
      <SearchInput placeholder={placeholder} onChange={handleInputChange} />
    </SearchBarContainer>
  );
};
