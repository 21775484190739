import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { CustomToaster } from '../common/toast/CustomToaster';
import {
  StudentPageBackground,
  StudentPageBody,
  StudentPageContainer,
  StudentPageContent,
  StudentPageMain,
} from './Styles';
import { StudentHeader } from './ui/header/StudentHeader';

export const StudentPage: FC = () => {
  return (
    <StudentPageBody>
      <StudentHeader />
      <StudentPageMain>
        <StudentPageBackground />
        <StudentPageContainer>
          <CustomToaster top="89px" />
          <StudentPageContent>
            <Outlet />
          </StudentPageContent>
        </StudentPageContainer>
        <StudentPageBackground />
      </StudentPageMain>
    </StudentPageBody>
  );
};
