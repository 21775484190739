import styled from 'styled-components';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { useGetCurrentEvent } from '../../../hooks/api/useGetCurrentEvent';
import { BlueButton } from '../main/button/BlueButton';
import { WhiteButton } from '../main/button/WhiteButton';
import { eventApi } from '../../../core/api/rtk-query/event.api';

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;

const BackButton = styled(WhiteButton)`
  padding: 0px;
  width: 250px;
  align-self: center;
  align-content: center;
  text-decoration: none;
  cursor: pointer;
`;

const BlueButtonStyled = styled(BlueButton)`
  width: 250px;
  align-self: center;
`;

interface ITaskPageFooterProps {
  isUserEventStillActive: boolean;
}

export const TaskPageFooter = ({ isUserEventStillActive }: ITaskPageFooterProps) => {
  const navigate = useNavigate();
  const { event } = useGetCurrentEvent();
  const [finishUserEvent] = eventApi.useFinishUserEventMutation();

  const eventId = event?.id;
  const groupId = event?.groupId;

  const STUDENT_EVENT_PAGE_URL = `/student/groups/${groupId}`;

  const handleFinishClick = () => {
    if (eventId) {
      finishUserEvent({ eventId });
      toast.success('Вы завершили задание');
      navigate(STUDENT_EVENT_PAGE_URL);
    }
  };

  return (
    <ButtonContainer>
      <BackButton as="a" href={STUDENT_EVENT_PAGE_URL}>
        Назад
      </BackButton>
      {isUserEventStillActive && <BlueButtonStyled onClick={handleFinishClick}>Завершить задание</BlueButtonStyled>}
    </ButtonContainer>
  );
};
