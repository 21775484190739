export const ROUTES = {
  rootPath: '*',
  auth: {
    path: '/auth/*',
    login: {
      path: 'login',
      fullPath: '/auth/login',
    },
    register: {
      path: 'register',
      fullPath: '/auth/register',
    },
  },
  student: {
    path: '/student/*',
    groups: {
      path: 'groups',
      fullPath: '/student/groups',
    },
    currentGroup: {
      path: 'groups/:groupId',
      fullPath: '/student/groups/:groupId',
    },
    currentTask: {
      path: 'tasks/:taskId',
      fullPath: '/student/tasks/:taskId',
    },
  },
  teacher: {
    path: '/teacher/*',
    groups: {
      path: 'groups',
      fullPath: '/teacher/groups',
    },
    currentGroup: {
      path: 'groups/:groupId',
      fullPath: '/teacher/groups/:groupId',
    },
    students: {
      path: 'students',
      fullPath: '/teacher/students',
    },
    currentStudent: {
      path: 'students/:studentId',
      fullPath: '/teacher/students/:studentId',
    },
    tasks: {
      path: 'tasks',
      fullPath: '/teacher/tasks',
    },
    currentTask: {
      path: 'tasks/:taskId',
      fullPath: '/teacher/tasks/:taskId',
    },
    createTask: {
      path: 'tasks/create',
      fullPath: '/teacher/tasks/create',
    },
    editTask: {
      path: 'tasks/edit/:taskId',
      fullPath: '/teacher/tasks/edit/:taskId',
    },
  },
};
