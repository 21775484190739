import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { TypeRootState } from '../store/store';

export const api = createApi({
  reducerPath: 'api',
  tagTypes: [
    'User',
    'Events',
    'UserEvents',
    'UsersInEvent',
    'UsersWhoNotStart',
    'UsersAttempts',
    'Group',
    'UsersInGroup',
    'Problems',
  ],
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}`,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as TypeRootState).auth.token;

      if (token) headers.set('Authorization', `Bearer ${token}`);

      return headers;
    },
  }),
  endpoints: builder => ({}),
});
