import React, { FC } from 'react';
import { LogoContainer, NavContainer, SideBar, SideBarContent } from '../../Styles';
import { LogoColor, MathDepLogo } from '../../../common/header/MathDepLogo';
import { NavElements } from './navigation/NavElements';

export const TeacherSideBar: FC = () => {
  return (
    <SideBar>
      <SideBarContent>
        <LogoContainer>
          <MathDepLogo color={LogoColor.WHITE} />
        </LogoContainer>
        <NavContainer>
          <NavElements />
        </NavContainer>
      </SideBarContent>
    </SideBar>
  );
};
