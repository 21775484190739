import { FC } from 'react';
import styled from 'styled-components';
import { BlueButton } from '../../../../common/main/button/BlueButton';

type AddNewTaskButtonProps = {
  imgSrc: string;
  name: string;
  onClick: () => void;
};

const ButtonImg = styled.img`
  width: 36px;
  height: 36px;
`;

const ButtonArea = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  gap: 6px;
`;

export const AddNewEventButton: FC<AddNewTaskButtonProps> = ({ imgSrc, name, onClick }) => {
  return (
    <BlueButton style={{ width: '173px', height: '40px', margin: '0 0 25px 0' }} onClick={onClick}>
      <ButtonArea>
        <ButtonImg src={imgSrc} alt={'addTaskButton'} />
        {name}
      </ButtonArea>
    </BlueButton>
  );
};
