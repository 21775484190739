import { useNavigate } from 'react-router-dom';
import { Role } from '../core/types/user.interface';
import { useAuth } from './useAuth';

export const useNavigateTo = () => {
  const { role } = useAuth();
  const navigate = useNavigate();

  if (role === Role.Student) {
    return (to: string) => navigate(`/student/${to}`);
  } else {
    return (to: string) => navigate(`/teacher/${to}`);
  }
};
