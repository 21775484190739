import styled from 'styled-components';
import { TaskFormInput } from '../../../../../../common/main/form/TaskFormInput';
import { BlueButton } from '../../../../../../common/main/button/BlueButton';

export const DateSelectionWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const DateTimeControls = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
`;

export const DateContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 5px;
`;

export const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 5px;
`;

export const IncreaseDateTimeButton = styled(BlueButton)`
  height: 32px;
  padding: 0px;
`;

export const DateInput = styled(TaskFormInput)`
  width: 110px;
  height: 40px;
`;

export const TimeInput = styled(TaskFormInput)`
  width: 90px;
  height: 40px;
`;
