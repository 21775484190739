import { FC, useState } from 'react';
import styled from 'styled-components';
import {
  BodySmallRegularText,
  SecondaryHeadingTextForEmptyStates,
  BodyBigBoldTextLikeH1,
  BodyBigBoldTextLikeH2,
} from '../../../../../styles/Text';
import { TaskCard } from './taskCard';
import { problemApi } from '../../../../../core/api/rtk-query/problem.api';
import { IProblem } from '../../../../../core/types/problem.interface';
import { BlueButton } from '../../../../common/main/button/BlueButton';
import { CloseButton } from '../../../../common/main/button/CloseButton';
import { PaginationBlock } from '../../../../common/main/pagination/PaginationBlock';

const PAGE_SIZE = 10;

const TaskBankPopupContentStyled = styled.div`
  width: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const TaskBankPopupHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const TaskBankPopupBody = styled.div`
  display: flex;
  flex-direction: row;
`;

const ChooseTasks = styled.div`
  width: 50%;
  display: flex;
  flex: 50%;
  flex-direction: column;
  padding-right: 25px;
  border-right: solid #e1e1e1 1px;
`;

const SelectedTasks = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  padding-left: 25px;
  flex: 50%;
`;

const SearchStyled = styled.input`
  width: 100%;
  border: solid #e7e7e7 1px;
  border-radius: 6px;
  margin-top: 8px;
  -webkit-appearance: none;
  color: #787676;
  text-align: start;
  padding: 8px 12px;

  &:focus {
    border: solid #035bdf 1px;
    outline: none;
    -webkit-appearance: none;
  }

  &:hover {
    -webkit-appearance: none;
  }

  &:active {
    border: solid #035bdf 1px;
    outline: none;
    -webkit-appearance: none;
  }
`;

const SaveButtonStyled = styled(BlueButton)`
  width: 250px;
  margin-top: 30px;
  align-self: end;
`;

interface IProblemWithPoints extends IProblem {
  points: number;
}

interface TaskBankPopupContentProps {
  onClose: () => void;
  onSave: (tasks: IProblem[]) => void;
  taskListToEdit?: IProblem[];
  isPointsChangeValidationError?: (boolean: boolean) => void;
}

interface SaveTaskBankPopupButtonProps {
  hasValidationError: boolean;
  selectedTasks: IProblemWithPoints[];
  onSave: (tasks: IProblemWithPoints[]) => void;
  onClose: () => void;
}

const SaveTaskBankPopupButton: React.FC<SaveTaskBankPopupButtonProps> = ({
  hasValidationError,
  selectedTasks,
  onSave,
  onClose,
}) => {
  const saveSelectedTasks = () => {
    if (hasValidationError) return;

    onSave(selectedTasks);
    onClose();
  };
  return (
    <>
      <SaveButtonStyled
        onClick={saveSelectedTasks}
        style={hasValidationError ? { backgroundColor: 'grey', pointerEvents: 'none' } : {}}
      >
        Сохранить
      </SaveButtonStyled>
      {hasValidationError && (
        <BodySmallRegularText style={{ color: 'rgb(243, 22, 62)', alignSelf: 'end' }}>
          Исправьте ошибки с баллами в выбранных заданиях!
        </BodySmallRegularText>
      )}
    </>
  );
};

const getBankTasks = (problems: IProblem[] | undefined, selectedTasks: IProblemWithPoints[]) => {
  if (problems) {
    return problems.filter(problem => !selectedTasks.some(t => t.hash === problem.hash));
  }
  return [];
};

export const TaskBankPopupContent: FC<TaskBankPopupContentProps> = ({ onClose, onSave, taskListToEdit }) => {
  const [prefix, setPrefix] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [selectedTasks, setselectedTasks] = useState<IProblemWithPoints[]>(taskListToEdit || []);
  const [hasValidationError, setHasValidationError] = useState<boolean>(false);

  const { data: problems } = problemApi.useGetProblemsQuery({ pageNumber: currentPage, pageSize: PAGE_SIZE, prefix });
  const totalPages = problems?.number ? Math.ceil(problems.number / PAGE_SIZE) : 1;

  const bankTasks = getBankTasks(problems?.problems, selectedTasks);

  const handleSelectTask = (selectedTask: IProblem) => {
    setselectedTasks(prevTasks => {
      const newTasks = [...prevTasks, { ...selectedTask, points: 1 }];

      const remainingTasks = getBankTasks(problems?.problems, newTasks);

      if (!remainingTasks.length && currentPage < totalPages) {
        setCurrentPage(prevPage => prevPage + 1);
      }

      return newTasks;
    });
  };

  const handleDeselectTask = (selectedTask: IProblem) => {
    setselectedTasks(prevTasks => prevTasks.filter(p => p.hash !== selectedTask.hash));
  };

  const handlePointsChange = (taskHash: string, points: number) => {
    setselectedTasks(prevTasks => prevTasks.map(task => (task.hash === taskHash ? { ...task, points } : task)));
  };

  const handlePointsValidation = (isError: boolean) => {
    setHasValidationError(isError);
  };

  return (
    <TaskBankPopupContentStyled>
      <TaskBankPopupHeader>
        <BodyBigBoldTextLikeH1>Выбор задач</BodyBigBoldTextLikeH1>
        <CloseButton onClick={onClose}></CloseButton>
      </TaskBankPopupHeader>
      <TaskBankPopupBody>
        <ChooseTasks>
          <BodyBigBoldTextLikeH2>Банк</BodyBigBoldTextLikeH2>
          <SearchStyled type={'search'} placeholder="Поиск" value={prefix} onChange={e => setPrefix(e.target.value)} />
          {problems?.problems.length ? (
            <>
              {bankTasks.map(problem => (
                <TaskCard
                  key={`${problem.hash}-${Math.random()}`}
                  {...problem}
                  isTaskSelected={false}
                  onAdd={() => handleSelectTask(problem)}
                />
              ))}
              <PaginationBlock
                pageNumber={currentPage}
                totalPages={totalPages}
                onPageChange={(pageNumber: number) => setCurrentPage(pageNumber)}
              />
            </>
          ) : (
            <SecondaryHeadingTextForEmptyStates>Ничего не найдено!</SecondaryHeadingTextForEmptyStates>
          )}
        </ChooseTasks>
        <SelectedTasks>
          <BodyBigBoldTextLikeH2>Выбрано</BodyBigBoldTextLikeH2>
          {selectedTasks.map(problem => (
            <TaskCard
              key={`${problem.hash}`}
              {...problem}
              isTaskSelected={true}
              onPointsChange={handlePointsChange}
              points={problem.points}
              isPointsChangeValidationError={handlePointsValidation}
              onDelete={() => handleDeselectTask(problem)}
            />
          ))}
          {!selectedTasks.length ? (
            <SecondaryHeadingTextForEmptyStates>Вы еще ничего не выбрали!</SecondaryHeadingTextForEmptyStates>
          ) : (
            <SaveTaskBankPopupButton
              hasValidationError={hasValidationError}
              selectedTasks={selectedTasks}
              onSave={onSave}
              onClose={onClose}
            />
          )}
        </SelectedTasks>
      </TaskBankPopupBody>
    </TaskBankPopupContentStyled>
  );
};
