export const amount = (amount: number, one: string, few: string, many: string) => {
  const lastDigit = amount % 10;
  const lastTwoDigits = amount % 100;

  if (lastTwoDigits >= 11 && lastTwoDigits <= 14) {
    return `${amount} ${many}`;
  }
  if (lastDigit === 1) {
    return `${amount} ${one}`;
  }
  if (lastDigit >= 2 && lastDigit <= 4) {
    return `${amount} ${few}`;
  }
  return `${amount} ${many}`;
};

export const eventsAmount = (eventsAmount: number) => {
  return amount(eventsAmount, 'задание', 'задания', 'заданий');
};

export const studentsAmount = (studentsAmount: number) => {
  return amount(studentsAmount, 'студент', 'студента', 'студентов');
};

export const pointsAmount = (pointsAmount: number) => {
  return amount(pointsAmount, 'балл', 'балла', 'баллов');
};

export const problemsAmount = (problemsAmount: number) => {
  return amount(problemsAmount, 'задача', 'задачи', 'задач');
};
