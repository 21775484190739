import { useState } from 'react';
import { BodyBigBoldText, BodySmallRegularText } from '../../../../../../../styles/Text';
import {
  DurationSelectorWrapper,
  DurationControls,
  DurationButtonsContainer,
  DurationInput,
  SetDurationButton,
} from './Styles';
import { ChooseDurationComponentProps } from './types';

const durationOptions = [
  { label: '45 минут', value: '45' },
  { label: '60 минут', value: '60' },
  { label: '90 минут', value: '90' },
];

export const ChooseDurationComponent: React.FC<ChooseDurationComponentProps> = ({ onDurationChange }) => {
  const [duration, setDuration] = useState<string>(durationOptions[0].value);
  const [durationValidationError, setDurationValidationError] = useState<string | null>(null);

  const handleManualDurationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setDuration(value);
    const updatedValue = Number(value);

    if (onDurationChange) {
      if (isNaN(updatedValue)) {
        setDurationValidationError('Укажите число');
      }

      if (!isNaN(updatedValue)) {
        if (updatedValue <= 0) {
          setDurationValidationError('Указанное число должно быть больше 0');
        } else {
          setDurationValidationError(null);
          onDurationChange(updatedValue);
        }
      }
    }
  };

  const handleButtonDurationClick = (duration: string) => () => {
    setDuration(duration);
    setDurationValidationError(null);
    onDurationChange(Number(duration));
  };

  return (
    <DurationSelectorWrapper>
      <BodyBigBoldText>
        Время выполнения <span style={{ color: '#F3163E' }}>*</span>
      </BodyBigBoldText>
      <DurationControls>
        <DurationInput value={duration} onChange={handleManualDurationChange} />
        <span>мин.</span>
        <DurationButtonsContainer>
          {durationOptions.map(option => (
            <SetDurationButton
              key={option.value}
              name={`set${option.value}min`}
              type="button"
              onClick={handleButtonDurationClick(option.value)}
            >
              {option.label}
            </SetDurationButton>
          ))}
        </DurationButtonsContainer>
      </DurationControls>
      {durationValidationError && (
        <BodySmallRegularText style={{ color: 'rgb(243, 22, 62)' }}>{durationValidationError}</BodySmallRegularText>
      )}
    </DurationSelectorWrapper>
  );
};
