import { FC } from 'react';
import styled from 'styled-components';
import { CardInfoItem, CardInfoItemProps } from './info/CardInfoItem';

const CardInfoStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  color: #787676;
`;

interface CardInfoProps {
  conditions: CardInfoItemProps[];
}

export const CardInfo: FC<CardInfoProps> = ({ conditions }) => {
  return (
    <CardInfoStyled>
      {conditions.map((item, index) => {
        return <CardInfoItem key={index} imgSrc={item.imgSrc} condition={item.condition} />;
      })}
    </CardInfoStyled>
  );
};
