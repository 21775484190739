import { FC } from 'react';
import styled from 'styled-components';
import { BodyBigBoldText } from '../../../../../../../styles/Text';
import { TaskPageScores } from '../../../../../../common/tasks/TaskPageScores';
import { Score } from '../../../../../../../hooks/useGetCurrentEventScore';

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-bottom: 2px solid #e1e1e1;
`;

const TaskNumText = styled.h3`
  color: #333;
  font-family: Noto Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
`;

interface CardHeaderProps {
  taskNum: number;
  isUserEventStillActive: boolean;
  score: Score;
}

export const TaskHeader: FC<CardHeaderProps> = ({ taskNum, isUserEventStillActive, score }) => {
  const userScore = score.score;
  const maxScore = score.all;
  const isHomeworkTaskDone = score.isSend && score.isShown;
  const shouldShowScores = !isUserEventStillActive || isHomeworkTaskDone;

  return (
    <HeaderContainer>
      <TaskNumText>{`Задача ${taskNum + 1}`}</TaskNumText>
      {!shouldShowScores && <BodyBigBoldText style={{ color: '#787676' }}>{`${maxScore} балл`}</BodyBigBoldText>}
      {shouldShowScores && <TaskPageScores userScore={userScore} maxScore={maxScore} />}
    </HeaderContainer>
  );
};
