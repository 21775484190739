import { FC } from 'react';
import styled from 'styled-components';
import { IProblem, IProblemAttempts } from '../../../../../core/types/problem.interface';
import { Score } from '../../../../../hooks/useGetCurrentEventScore';
import { TaskCard } from './card/TaskCard';
import { TaskPageFooter } from '../../../../common/tasks/TaskPageFooter';

const StudentTasksContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

interface TasksListProps {
  problems: IProblem[] | undefined;
  attempts: IProblemAttempts[] | undefined;
  scores: Score[] | undefined;
  eventId: string | undefined;
  isUserEventStillActive: boolean;
}

export const TasksList: FC<TasksListProps> = ({ problems, attempts, scores, isUserEventStillActive }) => {
  return (
    <>
      {problems && attempts && scores && (
        <StudentTasksContent>
          {problems.map((problem, index) => (
            <TaskCard
              key={index}
              index={index}
              problem={problem}
              attempt={attempts.find(attempt => attempt.problemId === problem.id)}
              score={scores[index]}
              isUserEventStillActive={isUserEventStillActive}
            />
          ))}
          <TaskPageFooter isUserEventStillActive={isUserEventStillActive} />
        </StudentTasksContent>
      )}
    </>
  );
};
