import { GlobalStyles } from './GlobalStyles';
import { BrowserRouter } from 'react-router-dom';
import { useRoutes } from './routes/routes';

export const App = () => {
  const routes = useRoutes();

  return (
    <>
      <GlobalStyles />
      <BrowserRouter>{routes}</BrowserRouter>
    </>
  );
};
