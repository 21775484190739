import styled from 'styled-components';

export const ChooseTaskContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
`;

export const EmptyTaskList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
`;

export const SelectedTasksList = styled.div`
  display: flex;
  flex-direction: column;
`;
