import React, { FC } from 'react';
import styled from 'styled-components';
import { PaginationButton } from './button/PaginationButton';

export interface PaginationBlockProps {
  pageNumber: number;
  totalPages: number;
  onPageChange: (pageNumber: number) => void;
}

export const PaginationBlock: FC<PaginationBlockProps> = ({ pageNumber, totalPages, onPageChange }) => {
  return <PaginationContainer>{getPaginationButtons(pageNumber, totalPages, onPageChange)}</PaginationContainer>;
};

const PaginationContainer = styled.div`
  margin-top: 5px;
  display: flex;
  gap: 5px;
  margin-left: auto;
`;

// TODO refactor
const getPaginationButtons = (pageNumber: number, totalPages: number, onPageChange: (pageNumber: number) => void) => {
  let pagination = [],
    i = 1;

  while (i <= totalPages) {
    let idx = i;
    if (i === 1 || i === totalPages || (i >= pageNumber - 2 && i <= pageNumber + 2)) {
      pagination.push(
        <PaginationButton active={idx === pageNumber} key={idx} onClick={() => onPageChange(idx)}>
          {idx}
        </PaginationButton>,
      );
      i++;
    } else {
      pagination.push(
        <PaginationButton active={idx === pageNumber} key={idx} onClick={() => onPageChange(idx)}>
          ...
        </PaginationButton>,
      );
      i = i < pageNumber ? pageNumber - 2 : totalPages;
    }
  }
  return pagination;
};
