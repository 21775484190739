import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { CustomToaster } from '../common/toast/CustomToaster';
import { AuthPageBackground, AuthPageBody, AuthPageContainer, AuthPageContent, AuthPageMain, Logo } from './Styles';
const logo: string = require('../../assets/images/logo-black.svg').default;

export const AuthPage: FC = () => {
  return (
    <AuthPageBody>
      <AuthPageContainer>
        <CustomToaster top="5px" />
        <AuthPageMain>
          <Logo alt="itmo logo" src={logo} />
          <AuthPageContent>
            <Outlet />
          </AuthPageContent>
        </AuthPageMain>
      </AuthPageContainer>
      <AuthPageBackground />
    </AuthPageBody>
  );
};
