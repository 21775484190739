import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../routes/routes.config';
import styled from 'styled-components';

export enum LogoColor {
  BLACK,
  WHITE,
}

const Logo = styled.img`
  cursor: pointer;
`;

export const MathDepLogo: FC<{ color?: LogoColor }> = ({ color = LogoColor.BLACK }) => {
  const module =
    color === LogoColor.BLACK
      ? require('../../../assets/images/logo-black.svg')
      : require('../../../assets/images/logo-white.svg');
  const logo = module.default;

  const navigation = useNavigate();

  // TODO: не переходить если пользователь находится на странице таски, ограниченной по времени
  const handleClick = () => {
    navigation(ROUTES.rootPath);
  };

  return <Logo src={logo} alt={'logo'} onClick={handleClick} />;
};
