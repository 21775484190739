import { FC } from 'react';
import { EventGridStyled } from './styles';
import { EventCard } from './card/EventCard';
import { EventGridProps } from './types';

export const EventsGrid: FC<EventGridProps> = ({ events }) => {
  return (
    <EventGridStyled>
      {events.map((events, index) => {
        return <EventCard key={index} {...events} />;
      })}
    </EventGridStyled>
  );
};
