import styled from 'styled-components';

export const GroupCardContainer = styled.div`
  height: 292px;

  background-color: #ffffff;
  box-shadow: 0px 4px 7px 1px rgba(0, 0, 0, 0.06);
  border-radius: 6px;
`;

export const GroupCardContent = styled.div`
  box-sizing: border-box;
  padding: 30px 20px;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const GroupCardDiv = styled.div`
  display: flex;
  flex-direction: column;
`;
