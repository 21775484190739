import axios from 'axios';
import { getContentType } from '../../utils/api.utils';

const $api = axios.create({
  withCredentials: false,
  baseURL: process.env.REACT_APP_API_URL,
  headers: getContentType(),
});

export default $api;
