import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-hot-toast';
import { ILoginFields } from '../../../components/auth/login/ui/form/LoginForm';
import { IRegisterFields } from '../../../components/auth/register/ui/form/RegisterForm';
import { toastError } from '../../../utils/api.utils';
import { IAuthData, IRegisterData } from '../../services/auth/auth.helper';
import { AuthService } from '../../services/auth/auth.service';

export const login = createAsyncThunk<IAuthData, ILoginFields>(
  'auth/login',
  async ({ isuNumber, password }, thunkAPI) => {
    try {
      const response = await AuthService.login(isuNumber, password);
      toast.success('Вход выполнен успешно');
      return response;
    } catch (e) {
      toastError(e);
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const register = createAsyncThunk<IRegisterData, IRegisterFields>(
  'auth/register',
  async ({ isuNumber, email, name, isuGroup, password }, thunkAPI) => {
    try {
      const response = await AuthService.register(isuNumber, email, name, password, isuGroup);
      toast.success('Регистрация выполнена успешно');
      return response;
    } catch (e) {
      toastError(e);
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const logout = createAsyncThunk('auth/logout', async () => {
  toast.success('Выход выполнен успешно');
});
