import React, { FC } from 'react';
import { LogoContainer, StudentPageFixedHeader } from '../../Styles';
import { LogoColor, MathDepLogo } from '../../../common/header/MathDepLogo';
import { PersonalInformation } from '../../../common/header/PersonalInformation';
import { LogOut } from '../../../common/header/LogOut';

export const StudentHeader: FC = () => {
  return (
    <StudentPageFixedHeader>
      <LogoContainer>
        <MathDepLogo color={LogoColor.BLACK} />
      </LogoContainer>
      <PersonalInformation />
      <LogOut />
    </StudentPageFixedHeader>
  );
};
