import { FC, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { problemApi } from '../../../../../../../core/api/rtk-query/problem.api';
import { IProblemAttempts } from '../../../../../../../core/types/problem.interface';
import { BlueButton } from '../../../../../../common/main/button/BlueButton';
import { TaskInput } from '../../../../../../common/main/input/TaskInput';
import { Score } from '../../../../../../../hooks/useGetCurrentEventScore';

const TaskForm = styled.form`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

export interface ITaskFields {
  answer: string;
}

interface TaskFooterProps {
  score: Score;
  attempt: IProblemAttempts;
  isUserEventStillActive: boolean;
}

type TaskFooterContainerProps = {
  register: Function;
  score: Score;
  reRender: boolean;
  isUserEventStillActive: boolean;
};

const TaskFooterContainer = ({ register, score, reRender, isUserEventStillActive }: TaskFooterContainerProps) => {
  if (!isUserEventStillActive) {
    return (
      <TaskInput
        {...register('answer')}
        score={score}
        reRender={reRender}
        style={{ pointerEvents: 'none', border: 'white' }}
      />
    );
  }
  return (
    <>
      <TaskInput {...register('answer')} score={score} reRender={reRender} style={{ border: 'white' }} />
      <BlueButton style={{ width: '150px' }}>Отправить</BlueButton>
    </>
  );
};

export const TaskFooter: FC<TaskFooterProps> = ({ attempt, score, isUserEventStillActive }) => {
  const [reRender, setReRender] = useState<boolean>(false);

  const { register, handleSubmit } = useForm<ITaskFields>({
    mode: 'onSubmit',
    defaultValues: {
      answer: attempt.sentMessage,
    },
  });

  const { taskId } = useParams();
  const [checkAnswer] = problemApi.useCheckAnswerMutation();

  useEffect(() => {
    setReRender(prev => !prev);
  }, [attempt]);

  const onSubmit: SubmitHandler<ITaskFields> = data => {
    checkAnswer({
      eventId: taskId,
      problemId: attempt.problemId,
      ...data,
    });
  };

  return (
    <>
      <TaskForm onSubmit={handleSubmit(onSubmit)}>
        <TaskFooterContainer
          register={register}
          score={score}
          reRender={reRender}
          isUserEventStillActive={isUserEventStillActive}
        ></TaskFooterContainer>
      </TaskForm>
    </>
  );
};
