import $api from '../../api/axios';
import { IAuthData, IRegisterData } from './auth.helper';

export const AuthService = {
  async login(isuNumber: number, password: string) {
    const response = await $api.post<IAuthData>(`/tokens`, {
      isuNumber,
      password,
    });

    $api.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`;

    return response.data;
  },

  async register(isuNumber: number, email: string, name: string, password: string, isuGroup: string) {
    const response = await $api.post<IRegisterData>(`/users`, {
      isuNumber,
      email,
      name,
      password,
      isuGroup,
    });

    return response.data;
  },
};
