import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { api } from '../../../core/api/api';
import { useActions } from '../../../hooks/useActions';
import { ROUTES } from '../../../routes/routes.config';
import { BodyBigRegularText } from '../../../styles/Text';

const LogOutContainer = styled.div`
  height: 24px;
  width: 82px;

  padding: 8px;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;

  cursor: pointer;
`;

const LogOutText = styled(BodyBigRegularText)`
  color: #f3163e;
`;

const LogOutIconContainer = styled.div`
  height: 24px;
  width: 24px;
`;

const LogOutIcon: FC = () => {
  const icon = require('../../../assets/icons/log-out.svg').default;

  return (
    <LogOutIconContainer>
      <img src={icon} alt={'log-out'} />
    </LogOutIconContainer>
  );
};

export const LogOut: FC = () => {
  const { logout } = useActions();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(api.util.resetApiState());
    logout();
    navigate(ROUTES.auth.login.fullPath);
  };

  return (
    <LogOutContainer onClick={handleClick}>
      <LogOutIcon />
      <LogOutText>Выход</LogOutText>
    </LogOutContainer>
  );
};
