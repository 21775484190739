import { Dayjs } from 'dayjs';
import { IEvent, IUserEvent } from '../core/types/event.interface';
import { getDueDate, getDurationInString } from './dayjs.utils';
import { eventsAmount, studentsAmount, problemsAmount } from './wordsDeclination';

const calendarImg = require('../../src/assets/icons/main/calendar.svg').default;
const timeLimitImg = require('../../src/assets/icons/main/time.svg').default;
const peopleImg = require('../../src/assets/icons/main/people.svg').default;
const noteImg = require('../../src/assets/icons/main/note.svg').default;

export const getGroupMaxPoints = (events: IEvent[] | undefined, groupId: string) => {
  let sum = 0;
  events
    ?.filter(event => event.groupId === groupId)
    .map(event => event.problems.forEach(problem => (sum += problem.points)));
  return sum;
};

export const getGroupUserPoints = (
  userEvents: IUserEvent[] | undefined,
  events: IEvent[] | undefined,
  groupId: string,
) => {
  let sum = 0;
  userEvents
    ?.filter(
      userEvent =>
        events
          ?.filter(event => event.groupId === groupId)
          .map(event => event.id)
          .includes(userEvent.eventId),
    )
    .map(userEvent => userEvent.problemAttempts.forEach(problemAttempt => (sum += problemAttempt.points)));
  return sum;
};

export const getInfo = (params: {
  date?: { start: Dayjs; end: Dayjs };
  problemsCount?: number;
  duration?: string;
  studentsCount?: number;
  eventsCount?: number;
}) => {
  const info = [];

  if (params.date) {
    info.push({
      imgSrc: calendarImg,
      condition: getDueDate(params.date.start, params.date.end),
    });
  }
  if (params.problemsCount) {
    info.push({
      imgSrc: noteImg,
      condition: problemsAmount(params.problemsCount),
    });
  }
  if (params.duration) {
    info.push({
      imgSrc: timeLimitImg,
      condition: getDurationInString(params.duration),
    });
  }
  if (params.studentsCount) {
    info.push({
      imgSrc: peopleImg,
      condition: studentsAmount(params.studentsCount),
    });
  }

  if (params.eventsCount) {
    info.push({
      imgSrc: noteImg,
      condition: eventsAmount(params.eventsCount),
    });
  }

  return info;
};

export const getMaxPoints = (event: IEvent) => {
  let sum = 0;
  event.problems.forEach(problem => (sum += problem.points));
  return sum;
};

export const getUserPoints = (userEvent?: IUserEvent) => {
  let sum = 0;
  userEvent?.problemAttempts.forEach(problemAttempt => (sum += problemAttempt.points));
  return sum;
};
