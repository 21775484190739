import styled from 'styled-components';
import { ButtonBigText } from '../../../../styles/Text';

export const WhiteButton = styled(ButtonBigText)`
  height: 36px;

  padding: 8px;
  border-radius: 4px;
  border: 1px solid #0b68fe;

  background-color: #ffffff;
  color: #0b68fe;

  text-align: center;
  cursor: pointer;

  &:hover {
    background-color: #fafafa;
  }

  &:active {
    background-color: #f8f8f8;
  }
`;
