import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { userApi } from '../../core/api/rtk-query/user.api';

export const useGetCurrentGroup = () => {
  const { data, isLoading } = userApi.useGetUserQuery();

  const { groupId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoading && !data?.groups.map(group => group.id).includes(groupId || '')) {
      navigate(-1);
    }
  }, [isLoading]);

  return { group: data?.groups.find(group => group.id === groupId), isLoading };
};
