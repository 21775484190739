import { FC } from 'react';
import { Popup } from '../../../../common/popup/Popup';
import { TaskBankPopupContent } from './TaskBankPopupContent';
import { IProblem } from '../../../../../core/types/problem.interface';

interface TaskBankPopupProps {
  onClose: () => void;
  onSave: (tasks: IProblem[]) => void;
  taskListToEdit?: IProblem[];
}

export const TaskBankPopup: FC<TaskBankPopupProps> = ({ onClose, onSave, taskListToEdit }) => {
  return (
    <Popup>
      <TaskBankPopupContent onClose={onClose} onSave={onSave} taskListToEdit={taskListToEdit} />
    </Popup>
  );
};
