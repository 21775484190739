import { FC } from 'react';
import styled from 'styled-components';
import { BodyBaseRegularText } from '../../../../styles/Text';

const ProgressBarStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;

  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
`;

const ProgressBarInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  align-items: flex-start;
  align-self: stretch;
`;

const ProgressBarContainer = styled.div`
  width: 100%;
  height: 8px;
  border-radius: 20px;
  background-color: #dae8ff;
`;

interface ProgressFillProps {
  progress: number;
}

const ProgressFill = styled.div<ProgressFillProps>`
  height: 100%;
  width: ${({ progress }) => progress}%;
  border-radius: 20px;
  background-color: #0b68fe;
`;

export interface ProgressBarProps {
  name: string;
  max: number;
  now: number;
}

export const ProgressBar: FC<ProgressBarProps> = ({ name, max, now }) => {
  return (
    <ProgressBarStyled>
      <ProgressBarInfo>
        <BodyBaseRegularText>{name}</BodyBaseRegularText>
        <BodyBaseRegularText style={{ color: '#787676' }}>
          {now}/{max}
        </BodyBaseRegularText>
      </ProgressBarInfo>
      <ProgressBarContainer>
        <ProgressFill progress={(now / max) * 100} />
      </ProgressBarContainer>
    </ProgressBarStyled>
  );
};
