import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { BodyBigRegularText } from '../../styles/Text';
import { FixedHeader } from '../common/header/FixedHeader';

const SideBarContent = styled.div`
  height: 242px;
  width: 100%;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
`;

const SideBar = styled.div`
  height: 100%;
  width: 224px;
  position: fixed;
  z-index: 200;
  top: 0;
  left: 0;
  background-color: #0b68fe;
  overflow-x: hidden;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.07);
`;

const LogoContainer = styled.div`
  width: 140px;
  height: 60px;
  margin: 0 52px 0 32px;

  img {
    width: 100%;
    height: 100%;
  }
`;

const TeacherPageBody = styled.div`
  height: 100%;
  margin-top: 72px;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const TeacherPageContent = styled.div`
  flex: 1;
  position: relative;

  margin-left: 224px;
  padding: 35px;
`;

const TeacherPageFixedHeader = styled(FixedHeader)`
  justify-content: right;
  gap: 30px;
  height: 72px;
  padding: 15px 30px;
`;

const NavContainer = styled.nav`
  margin-top: 30px;
  padding-left: 20px;
`;

const NavElementList = styled.ul`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  gap: 10px;
  list-style-type: none;
`;

const NavElementContainer = styled.li`
  height: 44px;
  width: 171px;
`;

const NavElementText = styled(BodyBigRegularText)`
  color: #ffffff;
  margin: 0;
`;

const StyledNavLink = styled(NavLink)`
  display: flex;
  flex-direction: row;
  gap: 10px;
  text-decoration: none;
  max-height: 100%;
  max-width: 100%;
  padding: 10px 8px 10px 10px;
  transition: 0.6s ease;
  border-radius: 4px;

  &:hover {
    background-color: #0962f2;
  }

  &.active {
    background-color: #035bdf;

    p {
      font-weight: bold;
    }
  }
`;

export {
  LogoContainer,
  NavContainer,
  NavElementContainer,
  NavElementList,
  NavElementText,
  SideBar,
  SideBarContent,
  StyledNavLink,
  TeacherPageBody,
  TeacherPageContent,
  TeacherPageFixedHeader,
};
