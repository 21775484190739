import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../../../../../routes/routes.config';
import { BodyBaseRegularText, BodyBigRegularText } from '../../../../../../../styles/Text';
import { TasksSidebarItem } from '../../Styles';
import { TimerProps } from './types';

export const Timer: FC<TimerProps> = ({ timeInSeconds }) => {
  const [timeLeft, setTimeLeft] = useState(timeInSeconds);

  const navigate = useNavigate();

  const onTimerFinish = () => {
    navigate(ROUTES.rootPath);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft(prevTime => prevTime - 1);
    }, 1000);

    if (timeLeft <= 0) {
      clearInterval(interval);
      onTimerFinish();
    }

    return () => clearInterval(interval);
  }, [timeLeft]);

  const formatTime = (seconds: number): string => {
    const hours = Math.floor(seconds / 3600);
    const mins = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return `${hours}ч ${mins}мин ${secs}с`;
  };

  return (
    <TasksSidebarItem>
      <BodyBaseRegularText style={{ color: '#787676' }}>До завершения</BodyBaseRegularText>
      <BodyBigRegularText style={{ color: '#0b68fe' }}>{formatTime(timeLeft)}</BodyBigRegularText>
    </TasksSidebarItem>
  );
};
