import { FC } from 'react';
import { GroupsGridStyled } from './styles';
import { GroupsGridProps } from './types';
import { GroupCard } from './card/GroupCard';

export const GroupsGrid: FC<GroupsGridProps> = ({ groups }) => {
  return (
    <GroupsGridStyled>
      {groups.map((group, index) => {
        return <GroupCard key={index} {...group} />;
      })}
    </GroupsGridStyled>
  );
};
