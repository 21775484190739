import { FC } from 'react';
import { ROUTES } from '../../../routes/routes.config';
import { AuthLink } from '../ui/AuthLink';
import { AuthTitle } from '../ui/AuthTitle';
import { LoginForm } from './ui/form/LoginForm';

export const LoginPage: FC = () => {
  return (
    <>
      <AuthTitle>Вход</AuthTitle>
      <LoginForm />
      <AuthLink to={ROUTES.auth.register.fullPath}>Зарегистрироваться</AuthLink>
    </>
  );
};
