import styled from 'styled-components';

const StudentGroupsWrapper = styled.div`
  width: 100%;
  flex: 1;
`;

const RatedStatusBg = styled.div`
  width: 65px;
  height: 24px;
  margin: 3px 0;
  border-radius: 4px;
  background-color: #d1ffd6;
`;

const NotRatedStatusBg = styled.div`
  width: 96px;
  height: 24px;
  margin: 3px 0;
  border-radius: 4px;
  background-color: #eaf0f9;
`;

export { StudentGroupsWrapper };
