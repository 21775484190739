import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { ROUTES } from '../../../../routes/routes.config';
import { eventApi } from '../../../../core/api/rtk-query/event.api';
import { Role } from '../../../../core/types/user.interface';
import { EventType, IEvent } from '../../../../core/types/event.interface';

import { useAuth } from '../../../../hooks/useAuth';
import { useGetCurrentGroup } from '../../../../hooks/api/useGetCurrentGroup';

import { getInfo, getMaxPoints, getUserPoints } from '../../../../utils/cards.utils';
import { getDayjs, getDayjsInGMT, getDuration } from '../../../../utils/dayjs.utils';

import { AddNewEventButton } from '../../../teacher/groups/events/ui/AddNewEventButton';
import { TabInfo, Tabs } from '../tabs/Tabs';
import { EventCardProps } from './grid/card/types';
import { EventsGrid } from './grid/EventsGrid';

import { EventsContainerProps } from './types';
import { MainHeadingText, SecondaryHeadingTextForEmptyStates } from '../../../../styles/Text';

const newTaskImg = require('../../../../assets/icons/main/plus.svg').default;

const studentCategoryTabs: TabInfo[] = [
  { name: 'Домашние работы' },
  { name: 'Контрольные работы' },
  { name: 'Рубежные работы' },
  { name: 'Экзамен' },
];

const teacherCategoryTabs: TabInfo[] = [...studentCategoryTabs, { name: 'Студенты' }];

const categoryTabs = {
  student: studentCategoryTabs,
  teacher: teacherCategoryTabs,
};

const EventsWrapper = styled.div`
  width: 100%;
  flex: 1;
`;

const EventsContainer = ({ isLoading, eventCards }: EventsContainerProps) => {
  if (isLoading) {
    return <SecondaryHeadingTextForEmptyStates>ЗАГРУЗКА</SecondaryHeadingTextForEmptyStates>;
  }

  if (!eventCards?.length) {
    return <SecondaryHeadingTextForEmptyStates>Здесь пока нет заданий</SecondaryHeadingTextForEmptyStates>;
  }

  return <EventsWrapper>{eventCards && <EventsGrid events={eventCards} />}</EventsWrapper>;
};

export const EventsSection: FC = () => {
  const { role } = useAuth();
  const navigate = useNavigate();

  const handleCreateClick = () => {
    navigate(ROUTES.teacher.createTask.fullPath);
  };
  const [activeTab, setActiveTab] = useState<number>(0);
  const [eventCards, setEventCards] = useState<EventCardProps[] | undefined>(undefined);

  const { group, isLoading: userIsLoading } = useGetCurrentGroup();
  const { data: events, isLoading: eventsIsLoading } = eventApi.useGetEventsQuery();
  const { data: userEvents, isLoading: userEventsIsLoading } = eventApi.useGetUserEventsQuery();
  const isLoading = userIsLoading || eventsIsLoading || userEventsIsLoading;

  const isStudent = role === Role.Student;
  const tabs = isStudent ? categoryTabs.student : categoryTabs.teacher;

  const getEvent = (event: IEvent): EventCardProps => {
    const now = getDayjs();
    const nowGMT = getDayjsInGMT();

    const groupEventStartTime = getDayjs(event.startTime);
    const groupEventEndTime = getDayjs(event.endTime);
    const isGroupEventStarted = groupEventStartTime < now;
    const isGroupEventEnded = groupEventEndTime < now;

    const info = getInfo({
      date: {
        start: groupEventStartTime,
        end: groupEventEndTime,
      },
      problemsCount: event.problems.length,
      duration: activeTab !== 0 ? event.duration : undefined,
    });

    const point = {
      name: 'Получено баллов',
      max: getMaxPoints(event),
      now: 0,
    };

    let whiteButtonText: string | undefined = undefined;
    let blueButtonText: string | undefined = undefined;

    const userEventData = userEvents?.find(userEvent => userEvent.eventId === event.id);

    if (!userEventData) {
      return {
        id: event.id,
        name: event.name,
        description: event.description,
        info,
        point: isStudent ? point : undefined,
        buttons: {
          whiteButtonText: isStudent ? whiteButtonText : 'Редактировать',
          blueButtonText: isStudent ? 'Начать' : 'Результаты',
        },
      };
    }

    const userEventStartTime = getDayjsInGMT(userEventData.startTime);
    const userEventDuration = getDuration(event.duration);
    const userEventTimer = userEventDuration - nowGMT.diff(userEventStartTime);
    const isUserEventActive = userEventTimer > 0;
    const isUserEventManuallyFinished = userEventData.isManuallyFinished;
    const isEventOver = isGroupEventEnded || isUserEventManuallyFinished;

    const isHomework = EventType[event.type] === EventType.Homework;
    const isExamEnded = !isHomework && !isUserEventActive;

    if (isEventOver || isExamEnded) {
      whiteButtonText = 'Смотреть';
    }

    if (isGroupEventStarted && !whiteButtonText) {
      blueButtonText = 'Продолжить';
    }

    point.now = getUserPoints(userEvents?.find(userEvent => userEvent.eventId === event.id));

    return {
      id: event.id,
      name: event.name,
      description: event.description,
      info,
      point,
      buttons: {
        blueButtonText,
        whiteButtonText,
      },
    };
  };

  useEffect(() => {
    if (!isLoading) {
      setEventCards(
        events
          ?.filter(event => event.groupId === group?.id && event.type === Object.keys(EventType)[activeTab])
          .map(event => getEvent(event)),
      );
    }
  }, [isLoading, activeTab, events, group, userEvents]);

  return (
    <>
      {group && <MainHeadingText style={{ marginBottom: 30 }}>{group.name}</MainHeadingText>}
      {!isStudent && <AddNewEventButton imgSrc={newTaskImg} name={'Новое задание'} onClick={handleCreateClick} />}
      <Tabs tabs={tabs} activeTab={activeTab} handleClick={index => setActiveTab(index)} />
      <EventsContainer isLoading={isLoading} eventCards={eventCards} />
    </>
  );
};
