import renderMathInElement from 'katex/dist/contrib/auto-render';
import 'katex/dist/katex.css';
import { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { BodyBigRegularText } from '../../../../styles/Text';

const LatexContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;
// Функция разделяет математические формулы LaTeX от обычного текста для правильного рендеринга KaTeX, добавляя знаки доллара ($$) там, где это не сделано изначально. Во всех других случаях, на финальный рендер не влияет.
const formatLatexString = string => {
  return string
    .replace(/(\\textbf{(?:[^{}]*|{[^{}]*})*})/g, '$$$1$$')
    .replace(/(\\begin{equation\*})/g, '$$$$\\begin{equation*}')
    .replace(/(\\end{equation\*})/g, '\\end{equation*}$$$$');
};

export const Latex = ({ content }) => {
  const ref = useRef(null);

  const formattedLatexString = formatLatexString(content);

  useEffect(() => {
    if (ref.current) {
      renderMathInElement(ref.current, {
        delimiters: [
          { left: '$$', right: '$$', display: true },
          { left: '$', right: '$', display: false },
          { left: '\\', right: '\\', display: true },
          {
            left: '\\begin{align}',
            right: '\\end{align}',
            display: true,
          },
        ],
        throwOnError: false,
      });
    }
  }, [ref.current]);

  return (
    <LatexContent ref={ref}>
      <BodyBigRegularText>{formattedLatexString}</BodyBigRegularText>
    </LatexContent>
  );
};
